<template>
  <div id="page-tracking">
    <div class="xs-p-20 xs-fill-white height-100vh">
      <div class="form-section first-section mycontainer-main p-10" >
        <div class="row">
          <div class="col-sm-6">
            <input type="text" class="input xs-mb-10" v-on:keyup.enter="tracking" v-model="textSearch" v-bind:placeholder="$t('placeholder_input_tracking')"/>
          </div>
          <div class="col-sm-3">
            <button class="button button--primary " v-on:click="tracking">{{$t('button_search')}}</button>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <strong class="cl-red t15">{{ message}}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  components: {},
  mounted () {
    this.eventTrackingScreenLoad(this, {screen: {'name': 'tracking.search', 'type': 'tracking'}})

    this.$root.$emit('onChangeMetaTitle', this.$t(this.$route.meta.key_title))
  },
  data () {
    return {
      textSearch: '',
      message: ''
    }
  },
  watch: {
    textSearch: function (newValue, oldValue) {
      this.validForm()
    }
  },
  methods: {
    validForm () {
      this.message = ''
      let isEmail = false
      let message = ''

      // if (this.textSearch.indexOf('@') !== -1) {
      //   isEmail = true
      // }

      if (isEmail) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(this.textSearch)) {
          message = this.$t('validate_invalid_email')
        }
      }

      if (!this.textSearch) {
        message = this.$t('validate_tracking_order')
      }

      this.message = message
    },
    tracking () {
      this.validForm()
      if (this.message) {
        this.message = this.$t('validate_invalid_tracking')
        return false
      }
      var vm = this
      this.axios.apiOrder.tracking({'keyword': this.textSearch}, function (response) {
        vm.$router.push({name: 'tracker-order', params: {uuid: response.data.data.item.uuid}})
      }, function (data) {
        vm.message = data.error.message
      })
    }

  }
}
</script>
<style scoped>
  .form-section{
    border: none;
    box-shadow: none;
  }
</style>
